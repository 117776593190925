.h-100 {
    height: 100%;
}
.min-h-100 {
  min-height: 100%;
}
.w-100 {
    width: 100%;
}
.border-box {
    box-sizing: border-box;
}

.text-align-left {
    text-align: left;
}

.text-align-right {
    text-align: right;
}

.text-align-center {
    text-align: center;
}
.blue-100 {
    color: $green100;
}
.blue-300 {
    color: $green300;
}
.red-100 {
    color: $red100;
}
.font-regular {
    font-weight: $regular;
}
.font-medium {
    font-weight: $medium;
}

.font-sm {
  font-size: 0.95rem;
}
.font-m {
    font-size: 1rem;
}
.font-l {
    font-size: 1.08rem;
}

.font-uppercase {
    text-transform: uppercase;
}
.color-default {
    color: $grey800;
}
.color-blue {
    color: $green300;
}
.pointer {
    cursor: pointer;
}
.d-block {
    display: block;
}
.d-none {
    display: none;
}
.transition {
    transition: $fast-transition;
}
.rotating {
    @include rotate-animation(600ms);
}
.on-scroll {
    cursor: grabbing;
    cursor: -webkit-grabbing;
}
.border-bottom {
    border-bottom: $border;
}
.opacity-0 {
    opacity: 0;
}
.visability-h {
    visibility: hidden;
}
