@import '../theme.scss';
@import '../mixins.scss';

html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Exo', sans-serif;
  font-size: 13px;
  font-weight: 400;
}
* {
  font-family: 'Exo', sans-serif;
}
ul.nav-group {
  list-style: none;
  margin: 0;
  padding: 0;
}

h3 {
  font-size: 21px !important;
  font-weight: 500 !important;
}

h2 {
  font-size: 24px !important;
  font-weight: 500 !important;
}

h1 {
  font-size: 28px !important;
  font-weight: 500 !important;
}

p {
  margin: 0;
}
b {
  font-weight: 500;
}

a {
  color: inherit;
  text-decoration: none;
  font-size: inherit;
}

button {
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  font-weight: 300;
  font-size: 1rem;
}

body .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.4);
}

svg-icon {
  display: flex;
}

.mat-drawer-backdrop {
  background-color: transparent !important;
}

.mat-mdc-menu-content {
  padding: 7px !important;
}

// Scroll bar
::-webkit-scrollbar-thumb,
::-webkit-bar-thumb {
  background: $green100;
  cursor: pointer;
  border-radius: 0.5rem;
}
::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.4rem;
  cursor: pointer;
}

.mat-tab-label {
  min-width: 145px !important;
}
.clipboard {
  cursor: copy;
  text-decoration: underline;
  &:active {
    color: red;
  }
}

.overflow-hidden {
  overflow: hidden !important;
}

.tooltip-container {
  text-align: center;
  z-index: 100;
  position: fixed;
  padding: 6px 12px;
  font-size: 0.66rem;
  font-weight: 600;
  line-height: initial;
  color: white;
  width: auto;
  background: #111111ee;
  box-sizing: border-box;
  opacity: 0;
  transform: translate(-50%, -30%);
  animation: tooltip-slide 0.18s ease-out 0.3s;
  animation-fill-mode: forwards;
  pointer-events: none;
}

@keyframes tooltip-slide {
  0% {
    opacity: 0;
    transform: translate(-50%, -30%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}
