@import './content';
@import '../mixins.scss';

.page-container {
  border-radius: $border-radius;
  box-shadow: 0 2px 12px #dfe3eb;
  height: 100%;
  width: 100%;
  background-color: #fff;

  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }

  .page-breadcrumbs {
    position: relative;
    border-bottom: 1px solid #dfe3eb;
    padding: 13px;

    width: 100%;
  }

  .page-body {
    padding: 13px;

    width: 100%;
    height: 100%;

    &.no-padding {
      padding: 0;
    }
  }

  pre {
    margin: 0 !important;
  }
}
