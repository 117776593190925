@import './mixins.scss';

$grey20: #f9f7f7;
$grey050: #F7F7F7;
$grey100: #E4E4E4;
$grey200: #C4C4C4;
$grey400: #888888;
$grey600: #020202;
$grey800: #2A2A2A;

$green100: #b9f0c9;
$green150: #87e6a5;
$green300: #88b767;

$red100: #FF2400;
$green50: #40DC7E;
$green100: #07b44c;
$orange100: #FFB519;
$orange200: #eda100;

$border: 1px solid $grey100;
$border-radius: 4px;
$box-shadow-card: 0px 2px 12px #DFE3EB;
$forms-atom-height: 34px;
$forms-atom-font: 1.15rem;
$form-margin-between: 20px;
$regular-padding: 20px;
$fast-transition: 0.3s all;
$transition: 1s all;
$regular: 400;
$medium: 500;
$widgets-space: 30px;
$header-height: 68px;
$dropdown-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
// $safari-height-gap: 80px;
$safari-height-gap: 15vh;

$animation-blink: blink 0.7s ease-in infinite;

@keyframes blink {
    from, to { opacity: 1 }
    50% { opacity: 0 }
}

.content-tab {
  padding: 20px 13px;
  box-sizing: border-box;
}

