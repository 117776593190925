@import './app/@theme/core.scss';
@import './app/@theme/theme.scss';
@import '~jsoneditor/dist/jsoneditor.min.css';

body {
  overflow: hidden;
}

markdown p:only-child {
  margin-bottom: 0;
}

markdown img {
  max-width: 100%;
  height: auto;
}
markdown .highlight {
  text-decoration: underline;
}

.added {
  color: green !important;
  font-weight: 800;
}
.removed {
  color: red !important;
  font-weight: 800;
}

.leader-line {
  z-index: 1000000;
}
