@import './content';
@import '../mixins.scss';

.box-v {
  border-radius: $border-radius;
  background-color: #fff;
  box-sizing: border-box;
  width: 100%;
  padding: 13px;
  box-shadow: 0 2px 12px #cfcfcf;
  min-height: 100%;
  height: 100%;
  // overflow-x: hidden;

  display: flex;
  flex-direction: column;

  atom-expand {
    margin: 13px 3px 3px;
  }

  breadcrumb {
    display: block;
    margin: -10px 0;
  }
}

.breadcrumbs {
  position: relative;
  padding-bottom: 13px;
  &::before {
    content: '';
    position: absolute;
    left: -13px;
    width: 13px;
    bottom: -1px;
    height: 1px;
    background: #dfe3eb;
  }

  &::after {
    content: '';
    position: absolute;
    right: -13px;
    width: 13px;
    bottom: -1px;
    height: 1px;
    background: #dfe3eb;
  }
}


.box-row {
  @include replace-padding(1rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.border {
    border-bottom: $border;
  }
  &.padding {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .left {
    display: flex;
    align-items: center;
    .info {
      margin-left: 15px;
      .title {
        cursor: pointer;
        color: $green300;
        @extend .font-medium;
        margin-bottom: 0.25rem;
      }
    }
  }
  .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &.column {
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      flex-grow: 1;
    }
  }
}

.route-header {
  margin: -10px 0;
}

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
    border-bottom: $border;
    @include replace-padding(1rem);
    h3 {
      @extend .font-l;
    }
}

.cdk-drop-list-dragging .cdk-drag {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
  box-sizing: border-box;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.expanded-content {
    background-color: $grey050;
    padding: 2rem;
}

.empty {
  color: rgba($color: #000000, $alpha: 0.6);
  font-size: 1rem;
  margin: 1rem 0;
}

mat-icon.expanded {
  transform: rotate(90deg);
}

.feature-list {
  @extend .box-v;
  .items-list {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .details {
    // background-color: $grey050;
    &.expanded {
      height: 152px;
      padding: 1rem;
      @include replace-padding(1rem);
      border-bottom: $border;
    }
  }

  .selector {
    padding: 15px 0 5px;
  }

  .empty {
    margin: 1rem 0;
  }

  .sub-titles {
    margin-top: 8px;
    font-size: 0.9rem;
    line-height: 12px;
    img {
      max-width: 12px;
      max-height: 12px;
    }
  }

}

.connected-items-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  .info-item-name-sn {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 20px;
    margin-right: 0;

    .info-item-sn {
      transform: translate(34px, -10px);
      padding: 5px;
      border-bottom: 2px solid #07b44c;
      border-left: 2px solid #07b44c;
      border-end-start-radius: 7px;
      min-width: fit-content;
      height: fit-content;
    }
  }

  .info-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    >*:first-child {
        margin-right: 8px;
    }
    svg, img {
        width: 22px;
        height: 22px;
    }
    .circle {
        padding: 2px;
        border-radius: 50%;
        border: $border;
        @include icon-color(#000);
    }
    &:not(:last-child) {
        margin-right: 20px;
    }
    .title {
        color: $green300;
        font-weight: $medium;
        font-size: 0.9rem;
        line-height: 12px;
        margin-bottom: 0;
        .additional {
          color: $grey800;
          font-weight: $regular;
          font-size: 0.8rem;
        }
    }
    .sub-titles {
      margin-top: 8px;
      font-size: 0.9rem;
      line-height: 12px;
      img {
        max-width: 12px;
        max-height: 12px;
      }
    }
  }
}



.tab-group {
  .mat-tab-label {
    height: 40px;
  }
}

.icon-hover {
  transition: $fast-transition;
  &:hover {
    transform: scale(1.2);
  }
}

.gm-style-iw-d {
  min-height: 110px;
  min-width: 300px;
}
